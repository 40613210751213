import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import utils from '../../utils/index';
import {
    fetchRegionSpecificUserCountry,
    fetchRegionSpecificUserMarket,
    fetchUserCountrySpecificTeam,
    toastTriggered,
    updateUserDetails,
    addNewUserDetails,
    fetchRegionSpecificUser
} from '../../redux/actions';
import BarLoader from "react-spinners/BarLoader";
import toastProperties from "../../assets/data/toastProperty";
import { useStyles } from "../resources/style.js";

const CreateNewUser = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { activeRegion, } = useSelector((state) => state.activeDynamicData);

    const targetUserCountry = useSelector((state) => state.targetUserCountry);
    const targetUserTeam = useSelector((state) => state.targetUserTeam);
    const targetUserMarket = useSelector((state) => state.targetUserMarket);
    const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
    const addedUser = useSelector((state) => state.addNewResources);
 
    const { /*userCountryLoading, */ country } = targetUserCountry;
    const {/* userTeamLoading,*/ team } = targetUserTeam;
    const {/* marketLoading, */ market } = targetUserMarket;
    const { regionList } = selectedRegion;


    const [formInput, setFormInput] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [KCId, setKCID] = useState('');
    const [userId, setUserId] = useState('');
    const [keyUser, setKeyUser] = useState('');
    const [Country, setCountry] = useState('');
    const [Market, setMarket] = useState('');
    const [Team, setTeam] = useState('');
    const [userRegion, setUserRegion] = useState('');
    const [addCustom, setAddCustom] = useState({
        country: false,
        market: false,
        team: false,
    });
    const [customMessage, setCustomMessage] = useState({});

    const { userDetails, closeModal, updateUser } = props;

    useEffect(()=>{
        if(!addedUser.Success && addedUser.error && !updateUser){
            toastProperties.error["description"] = addedUser.message;
            dispatch(toastTriggered([toastProperties.error]));
        }
    },[addedUser, dispatch, updateUser])

    useEffect(() => {
        let params = activeRegion
        dispatch(fetchRegionSpecificUserCountry(params))
        dispatch(fetchRegionSpecificUserMarket(params)).then((res)=>{
            let market = res.data.data[0].Market
            setMarket(market)
        });
    }, [activeRegion, dispatch]);

    useEffect(() => {
        if (Array.isArray(country) && country.length > 0) {
            let params = country[0]["User Base Country"]
            setCountry(params)
            dispatch(fetchUserCountrySpecificTeam(params)).then((res)=>{
                let team = res.data.data[0].Team
                setTeam(team)
            });
        }
    }, [country, dispatch]);

    useEffect(() => {
        if (Object.keys(userDetails).length > 0 && updateUser) {
            setFirstName(userDetails['First Name']);
            setLastName(userDetails['Last Name']);
            setEmail(userDetails['Email']);
            setKeyUser(userDetails["Key User "]);
            setKCID(userDetails[" KC ID"]);
            setUserId(userDetails.userId);
            setCountry(userDetails["User Base Country"]);
            setMarket(userDetails.Market);
            setTeam(userDetails.Team);
            setUserRegion(userDetails.Region);
        }
    }, [Email, formInput, updateUser, userDetails]);

    const UpdateFormInput = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        if (name === 'firstName') {
            formInput['firstName'] = value;
            setFirstName(value);
        }
        if (name === 'lastName') {
            formInput['lastName'] = value;
            setLastName(value);
        }
        else if (name === 'Email') {
            formInput['Email'] = value;
            setEmail(value);
        }
        else if (name === 'KCId') {
            formInput['KCId'] = value;
            setKCID(value)
        }
        else if (name === 'UserCountry') {
            setCountry(value);
            formInput['Country'] = value;
            let params = value
            dispatch(fetchUserCountrySpecificTeam(params))
        }
        else if (name === 'UserTeam') {
            formInput['Team'] = value;
            setTeam(value)
        }
        else if (name === 'UserMarket') {
            formInput['Market'] = value;
            setMarket(value)
        }
        else if (name === 'keyUser') {
            formInput['keyUser'] = value;
            setKeyUser(value)
        }
        else if (name === 'UserRegion') {
            formInput['region'] = value;
            setUserRegion(value)
        }
        setFormInput(formInput)
    };

    const trackEvents = (title, type) => {
        utils.userTrackingInfo({
            region: activeRegion,
            actionType: 'Landing Page Admin Activity',
            pageName: 'Admin Dashboard',
            reportName: title,
            pageDetails: type,
        });
    }

    const updateUserProfile = () => {
        let customObj = {};
        if (firstName === '' && lastName === '' && Email === '' && KCId === '' && Country === '' && Market === '' && Team === '') {
            customObj.error = true;
            customObj.customMessage = `All Input value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (firstName === '') {
            customObj.error = true;
            customObj.customMessage = `Name value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (Email === '') {
            customObj.error = true;
            customObj.customMessage = `Email value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (KCId === '') {
            customObj.error = true;
            customObj.customMessage = `KCID value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (Country === '') {
            customObj.error = true;
            customObj.customMessage = `Please Select the Country`;
            setCustomMessage(customObj);
        }
        else if (Market === '') {
            customObj.error = true;
            customObj.customMessage = `Please Select the Market`;
            setCustomMessage(customObj);
        }
        else if (Team === '') {
            customObj.error = true;
            customObj.customMessage = `Please Select the Team`;
            setCustomMessage(customObj);
        }
        else if (updateUser) {
            customObj.loading = true;
            setCustomMessage(customObj);
            formInput['firstName'] = firstName;
            formInput['lastName'] = lastName;
            formInput['Email'] = Email;
            formInput['KCId'] = KCId;
            formInput['userId'] = userId;
            formInput['Country'] = Country;
            formInput['Market'] = Market;
            formInput['Team'] = Team;
            formInput['region'] = userRegion;
            formInput['keyUser'] = keyUser;
            let params = {
                userInfo: formInput
            }
            dispatch(updateUserDetails(params))
                .then((res) => {
                    console.log('updateUserDetails', res, res.data.message)
                    toastProperties.success["description"] = res.data.message;
                    dispatch(toastTriggered([toastProperties.success]));
                    customObj.loading = false;
                    closeModal(false);
                    setCustomMessage(customObj);
                    trackEvents(firstName, `${firstName} user details is updated`)
                    let params = { region: activeRegion }
                    dispatch(fetchRegionSpecificUser(params));
                })
                .catch(() => {
                    toastProperties.error["description"] = `There is some problem while updating user details ${firstName} in region ${activeRegion}. Please try again in some time.`;
                    dispatch(toastTriggered([toastProperties.error]));
                    closeModal(true);
                    customObj.loading = false;
                    setCustomMessage(customObj);
                });
        }
        else {
            customObj.loading = true;
            setCustomMessage(customObj);
            formInput['firstName'] = firstName;
            formInput['lastName'] = lastName;
            formInput['Email'] = Email;
            formInput['KCId'] = KCId;
            formInput['Country'] = Country;
            formInput['Market'] = Market;
            formInput['Team'] = Team;
            formInput['region'] = userRegion;
            formInput['keyUser'] = keyUser;
            let params = {
                userInfo: formInput
            }
            dispatch(addNewUserDetails(params)).then((res) => {
                toastProperties.success["description"] = res.data.message;
                dispatch(toastTriggered([toastProperties.success]));
                closeModal(true);
                customObj.loading = false;
                setCustomMessage(customObj);
                trackEvents(firstName, `User ${firstName} is created for ${activeRegion}`)
                let params = { region: activeRegion }
                dispatch(fetchRegionSpecificUser(params));
            })
                .catch((err) => {
                    console.log('creatingUserErr', err, err.response, addedUser);
                    // toastProperties.error["description"] = 'There is some error creating a new parent module, Please try again in sometime.';
                    // dispatch(toastTriggered([toastProperties.error]));
                    closeModal(true)
                    setCustomMessage(customObj);
                    customObj.loading = false;
                    setCustomMessage(customObj);

                });
        }
    };

    const renderEventMessage = () => {
        if (customMessage.loading) {
            return (
                <div className="waiting_Loader">
                    <div className="prettier">Please wait...</div>
                    <BarLoader
                        size={150}
                        color={"#123abc"}
                        height={4}
                        width={100}
                        loading={true}
                    />
                </div>
            )
        }
        else if (customMessage.error) {
            return (
                <div className="col-12  col-xl-12 flex mt-0" style={{ justifyContent: "flex-start" }}>
                    <div className="col-12  col-xl-12 error"> Error : {customMessage.customMessage}</div>
                </div>
            )
        }
    };

    const addNewCoustom = (type, enable) => {
        let customFlag = {};
        if (type === 'country') {
            customFlag.country = enable;
            customFlag.market = addCustom.market;
            customFlag.team = addCustom.team;
            setAddCustom(customFlag);
            let country = enable ? '' : userDetails["User Base Country"];
            setCountry(country)
        }
        else if (type === 'market') {
            customFlag.market = enable;
            customFlag.country = addCustom.country;
            customFlag.team = addCustom.team;
            setAddCustom(customFlag);
            let market = enable ? '' : userDetails.Market;
            setMarket(market);
        }
        else if (type === 'team') {
            customFlag.market = addCustom.market;
            customFlag.country = addCustom.country;
            customFlag.team = enable;
            setAddCustom(customFlag);
            let team = enable ? '' : userDetails.Team;
            setTeam(team);
        }
    }

    const renderCountry = () => {
        if (!addCustom.country) {
            return (
                <div className="col-12  col-xl-12   tile-new">
                    <label className="tile-new">
                        Country
                    </label>
                    <select
                        value={Country}
                        className="form-control "
                        onChange={(e) => UpdateFormInput(e)}
                        name="UserCountry"
                    >
                        {
                            Array.isArray(country) && country.length > 0 ?
                                country.map((el, index) =>
                                (
                                    <option key={index} value={el["User Base Country"]}> {el["User Base Country"]}</option>
                                )
                                )
                                :
                                <option key={999} value="No"> No Country Data Available</option>
                        }
                    </select>
                    <div className={classes.justifyContentRight}>
                        <button
                            type="button"
                            className={classes.addCustomUser}
                            onClick={(e) => {
                                e.preventDefault();
                                addNewCoustom('country', true);
                            }}
                        >
                            Add New Country
                        </button>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="col-12  col-xl-12 form-group  tile-new">
                    <label className="tile-new">
                        Country
                    </label>
                    <input value={Country} name={'country'} type="text" className="form-control tile-new" placeholder="Enter the User Country" onChange={(e) => setCountry(e.target.value)} />
                    <div className={classes.justifyContentRight}>
                        <button
                            type="button"
                            className={classes.addCustomUser}
                            onClick={(e) => {
                                e.preventDefault();
                                addNewCoustom('country', false);
                            }}
                        >
                            Back
                        </button>
                    </div>
                </div>
            )
        }
    };

    const renderTeam = () => {
        if (!addCustom.team) {
            return (
                <div className="col-12  col-xl-12  tile-new">
                    <label className="tile-new">
                        Team
                    </label>
                    <select
                        value={Team}
                        className="form-control "
                        onChange={(e) => UpdateFormInput(e)}
                        name="UserTeam"
                    >
                        {
                            Array.isArray(team) && team.length > 0 ?
                                team.map((el, index) =>
                                (
                                    <option key={index} value={el["Team"]}> {el["Team"]}</option>
                                )
                                )
                                :
                                <option key={999} value="No"> No Team Data Available</option>
                        }
                    </select>
                    <div className={classes.justifyContentRight}>
                        <button
                            type="button"
                            className={classes.addCustomUser}
                            onClick={(e) => {
                                e.preventDefault();
                                addNewCoustom('team', true);
                            }}
                        >
                            Add New Team
                        </button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="col-12  col-xl-12 form-group  tile-new">
                    <label className="tile-new">
                        Team
                    </label>
                    <input value={Team} name={'Team'} type="text" className="form-control tile-new" placeholder="Enter the team" onChange={(e) => setTeam(e.target.value)} />
                    <div className={classes.justifyContentRight}>
                        <button
                            type="button"
                            className={classes.addCustomUser}
                            onClick={(e) => {
                                e.preventDefault();
                                addNewCoustom('team', false);
                            }}
                        >
                            Back
                        </button>
                    </div>
                </div>
            )
        }
    };

    const renderMarket = () => {
        if (!addCustom.market) {
            return (
                <div className="col-12  col-xl-12   tile-new">
                    <label className="tile-new">
                        Market
                    </label>
                    <select
                        value={Market}
                        className="form-control "
                        onChange={(e) => UpdateFormInput(e)}
                        name="UserMarket"
                    >
                        {
                            Array.isArray(market) && market.length > 0 ?
                                market.map((el, index) =>
                                (
                                    <option key={index} value={el["Market"]}> {el["Market"]}</option>
                                )
                                )
                                :
                                <option key={999} value="No"> No Market Data Available</option>
                        }
                    </select>
                    <div className={classes.justifyContentRight}>
                        <button
                            type="button"
                            className={classes.addCustomUser}
                            onClick={(e) => {
                                e.preventDefault();
                                addNewCoustom('market', true);
                            }}
                        >
                            Add New Market
                        </button>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="col-12  col-xl-12 form-group  tile-new">
                    <label className="tile-new">
                        Market
                    </label>
                    <input value={Market} name={'Market'} type="text" className="form-control tile-new" placeholder="Enter the Market" onChange={(e) => setMarket(e.target.value)} />
                    <div className={classes.justifyContentRight}>
                        <button
                            type="button"
                            className={classes.addCustomUser}
                            onClick={(e) => {
                                e.preventDefault();
                                addNewCoustom('market', false);
                            }}
                        >
                            Back
                        </button>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="container">
            <div className="col-12 sections my-2" id="basic_screen">
                <form className="form">
                    <div className="row">
                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="tile-new">
                                First Name
                            </label>
                            <input value={firstName} name={'firstName'} type="text" className="form-control tile-new" placeholder="Enter the user name" onChange={(e) => UpdateFormInput(e)} />
                        </div>
                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="tile-new">
                                Last Name
                            </label>
                            <input value={lastName} name={'lastName'} type="text" className="form-control tile-new" placeholder="Enter the user name" onChange={(e) => UpdateFormInput(e)} />
                        </div>
                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="tile-new">
                                User Email
                            </label>
                            <input value={Email} name={'Email'} type="text" className="form-control tile-new" placeholder="Enter short user email" onChange={(e) => UpdateFormInput(e)} />
                        </div>

                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="tile-new">
                                KC ID
                            </label>
                            <input value={KCId} name={'KCId'} type="text" className="form-control tile-new" placeholder="Enter the KC ID" onChange={(e) => UpdateFormInput(e)} />
                        </div>

                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="tile-new">
                                Key Users
                            </label>
                            <select
                                value={keyUser}
                                className="form-control "
                                onChange={(e) => UpdateFormInput(e)}
                                name="keyUser"
                            >
                                <option value="No"> No</option>
                                <option value="Yes"> Yes</option>
                            </select>
                        </div>

                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="tile-new">
                                Region
                            </label>
                            <select
                                value={userRegion}
                                className="form-control "
                                onChange={(e) => UpdateFormInput(e)}
                                name="UserRegion"
                            >
                                {
                                    Array.isArray(regionList) && regionList.length > 0 ?
                                        regionList.map((el, index) =>
                                        (
                                            <option key={index} value={el["region"]}> {el["region"]}</option>
                                        )
                                        )
                                        :
                                        <option key={999} value="No"> No region Data Available</option>
                                }
                            </select>
                        </div>

                        {renderCountry()}

                        {renderTeam()}

                        {renderMarket()}

                        {renderEventMessage()}

                        <div className="col-12  col-xl-12 form-group  flex mt-0" style={{ justifyContent: "center" }}>
                            <button type="button" className="btn btn-apply" onClick={(e) => { e.preventDefault(); updateUserProfile() }}>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateNewUser;

