import React, { useState, useEffect /*useContext */ } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { LayoutContext } from "../layoutContent/index";
import {
  getModuleContent,
  deleteResourcesLinks,
  toastTriggered,
} from "../../redux/actions/index";
import SelfService from "./selfServices";
import { PlusCircleFill } from "../../assets/icons/Icons";
import AddNewResouces from "../resources/addNewResources";
import DisplayOrder from "../resources/displayOrder";
import ContentDialog from "../EditBusinessUnit/dialog";
import utils from "../../utils/index";
import toastProperties from "../../assets/data/toastProperty";

const TabConfiguration = () => {
  const dispatch = useDispatch();

  const getPageContent = useSelector((state) => state.getPageContent);
  // const values = useContext(LayoutContext);

  const [selectedTab, setSelectedTab] = useState("PBI Pro User");
  const [selectedTabName, setSelectedTabName] = useState("");
  const [updateLinks, setUpdateLinks] = useState(false);
  const [displayOrder, setDisplayOrder] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [selRowData, setSelRowData] = useState({});

  const [tabledata, setData] = useState([]);

  const { data, loading } = getPageContent;

  const { activeRegion, activePage } = useSelector(
    (state) => state.activeDynamicData
  );

  useEffect(() => {
    if (activeRegion && activePage && activePage.key !== "Playbooks") {
      const params = {
        pageType: activePage.key,
        region: activeRegion,
      };
      dispatch(getModuleContent(params));
    }
  }, [dispatch, activePage, activeRegion]);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      data.sort(function (a, b) {
        if (a.contentKey < b.contentKey) {
          return -1;
        }
        if (a.contentKey > b.contentKey) {
          return 1;
        }
        return 0;
      });
      let contentKey = data[0].contentKey;
      setSelectedTab(data[0].contentKey);
      setSelectedTabName(data[0].contentKey);
      let userData = data.filter((el) => el.contentKey === contentKey);
      userData.sort(function (a, b) {
        return a.order - b.order;
      });
      userData.forEach((item, index) => {
        item.id = index + 1;
        return data;
      });
      console.log("@TabConfiguration", 111, data, userData);
      setData(userData);
    }
  }, [activeRegion, data]);

  const changeUserSelectedTab = (tab) => {
    setSelectedTab(tab);
    setSelectedTabName(tab);
    let userData = data.filter((el) => el.contentKey === tab);
    userData.sort(function (a, b) {
      return a.order - b.order;
    });
    userData.forEach((item, index) => {
      item.id = index + 1;
      return data;
    });
    console.log("@TabConfiguration", 222, data, userData);
    setData(userData);
  };

  const renderModuleTitle = () => {
    if (displayOrder) {
      return <span>Update Positions</span>;
    } else if (updateLinks) {
      return <span>Update Self Service Links</span>;
    } else {
      return <span>Create New Self Service</span>;
    }
  };

  const closeDialog = () => {
    setopenDialog(false);
  };

  const renderModuleContent = () => {
    if (displayOrder && !updateLinks) {
      return (
        <DisplayOrder
          rowDetails={selRowData}
          updateLinks={false}
          displayOrder={displayOrder}
          closeDialog={closeDialog}
          moduleData={tabledata}
        />
      );
    } else {
      return (
        <AddNewResouces
          rowDetails={selRowData}
          updateLinks={updateLinks}
          closeDialog={closeDialog}
          moduleType={"selfService"}
          moduleData={data}
        />
      );
    }
  };

  const renderContentDialog = () => {
    return (
      <ContentDialog
        contentTitle={renderModuleTitle()}
        openDialog={openDialog}
        closeDialog={closeDialog}
        moduleContent={renderModuleContent()}
      />
    );
  };

  const updateSelfServiceLink = (data) => {
    setopenDialog(true);
    setUpdateLinks(true);
    setDisplayOrder(false);
    setSelRowData(data);
  };

  const updateLinksDisplayOrder = (data) => {
    setopenDialog(true);
    setDisplayOrder(true);
    setUpdateLinks(false);
  };

  const createNewSelfService = () => {
    setopenDialog(true);
    setDisplayOrder(false);
    setUpdateLinks(false);
  };

  const renderCategories = () => {
    if (data && data.length > 0) {
      let uniqueData = utils.uniqueArrayData(data, "contentKey");
      return uniqueData.map((item) => (
        <button
          className={`btn btn-plain ${
            selectedTab === item["contentKey"] ? "active" : null
          }`}
          onClick={(e) => changeUserSelectedTab(item["contentKey"])}
        >
          {item["contentKey"]}
        </button>
      ));
    }
  };

  const deleteSelfService = (selectedRow) => {
    let text = `Are You Sure You Want to Delete the user ${selectedRow["displayName"]}   Parmanently? This Action Can't be UNDONE`;
    if (window.confirm(text)) {
      let params = { linkId: selectedRow.LinkId };
      dispatch(deleteResourcesLinks(params))
        .then((res) => {
          toastProperties.success["description"] = res.data.message;
          dispatch(toastTriggered([toastProperties.success]));
          const params = {
            pageType: activePage.key,
            region: activeRegion,
          };
          dispatch(getModuleContent(params));
        })
        .catch((err) => {
          toastProperties.error[
            "description"
          ] = `There is some problem while deleting the user details in region ${activeRegion}. Please try again in some time.`;
          dispatch(toastTriggered([toastProperties.error]));
        });
    }
  };

  return (
    <div className="container-fluid bg-gray full-screen-height">
      <div className="row bg-white dashboard-button-row">
        <div className="d-flex dashboard-button-row">
          {renderContentDialog()}
          {renderCategories()}
        </div>
        <button
          className={`btn btn-right-align btn-apply `}
          onClick={(e) => createNewSelfService(e)}
        >
          <PlusCircleFill /> Create New Self Service
        </button>
      </div>
      <div className="row bg-white">
        <SelfService
          data={utils.sortBy(tabledata, "order")}
          loading={loading}
          selectedTab={selectedTabName}
          updateSelfServiceLink={updateSelfServiceLink}
          updateLinksDisplayOrder={updateLinksDisplayOrder}
          deleteSelfServiceLink={deleteSelfService}
        />
      </div>
    </div>
  );
};

export default TabConfiguration;
