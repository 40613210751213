
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewResources, getModuleContent, toastTriggered, updateNewResources } from "../../redux/actions";
import { useStyles } from "./style.js";
import utils from "../../utils/index";
import toastProperties from "../../assets/data/toastProperty";

const ReportLinks = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { subRegion, rowDetails, updateLinks, closeDialog, moduleType, moduleData } = props;

  const { activeRegion, activePage } = useSelector((state) => state.activeDynamicData);

  const [displayName, setDisplayName] = useState(null);
  const [description, setDescription] = useState(null);
  const [linkForDocumentation, setLinkForDocumentation] = useState(null);
  const [category, setCategory] = useState(null);
  const [enabledCategory, setEnableCategory] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (updateLinks && rowDetails && Object.keys(rowDetails).length > 0) {
      setDisplayName(rowDetails['displayName']);
      setDescription(rowDetails['description']);
      setLinkForDocumentation(rowDetails['linkForDocumentation']);
      setCategory(rowDetails['contentKey']);
    }
  }, [rowDetails, updateLinks]);

  const UpdateFormInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    console.log('UpdateFormInput', name, value)
    if (name === 'displayName') {
      setDisplayName(value);
    }
    if (name === 'description') {
      setDescription(value);
    }
    else if (name === 'Links') {
      setLinkForDocumentation(value);
    }
    else if (name === 'Category') {
      setCategory(value)
    }
  };

  const addNewCaregory = (value) => {
    setEnableCategory(value);
  }

  const saveNewReportLinks = () => {

    let customObj = {};
    if (displayName === '' && description === '' && linkForDocumentation === '' && category === '') {
      customObj.error = true;
      customObj.customMessage = `All Input value Can't be Empty, Please Enter the Value`;
      setError(customObj);
    }
    else if (displayName === '') {
      customObj.error = true;
      customObj.customMessage = `displayName value Can't be Empty, Please Enter the Value`;
      setError(customObj);
    }
    else if (description === '') {
      customObj.error = true;
      customObj.customMessage = `description value Can't be Empty, Please Enter the Value`;
      setError(customObj);
    }
    else if (linkForDocumentation === '') {
      customObj.error = true;
      customObj.customMessage = `linkForDocumentation value Can't be Empty, Please Enter the Value`;
      setError(customObj);
    }
    else if (category === '') {
      customObj.error = true;
      customObj.customMessage = `Please Select the Country`;
      setError(customObj);
    }
    else {
      let obj = {
        linkId: rowDetails.LinkId,
        displayName: displayName,
        description: description,
        linkForDocumentation: linkForDocumentation.trim(),
        pageType: activePage.key,
        region: activeRegion,
        contentKey: category,
        subRegion: subRegion,
        order: 1,
        workspace: `${activeRegion} RGM PLAYBOOKS - ${process.env.REACT_APP_REPORT_WORKSPACE}`
      };
      let dispatchaAtion = updateLinks ? updateNewResources(obj) : createNewResources(obj)
      dispatch(dispatchaAtion)
        .then((res) => {
          toastProperties.success["description"] = res.data.message;
          dispatch(toastTriggered([toastProperties.success]));
          if (res) {
            const pageContent = {
              pageType: activePage.key,
              region: activeRegion,
            }
            dispatch(getModuleContent(pageContent));
            let trackingObject = {
              region: activeRegion,
              actionType: 'Click',
              pageName: activePage.key,
              attributeName: 'Resource Creation',
              reportName: displayName,
              pageDetails: JSON.stringify(obj),
              reportLink: linkForDocumentation,
            };
            utils.userTrackingInfo(trackingObject);
            closeDialog()

          }
        })
        .catch((err) => {
          closeDialog();
          // let message = err instanceof TypeError ? err.message : err.response.message;
          toastProperties.error["description"] = `There is some problem while creating the report details in region ${activeRegion}. Please try again in some time.`;
          dispatch(toastTriggered([toastProperties.error]));
        });
    }
  }

  const renderCategory = () => {
    let uniqueData = utils.uniqueArrayData(moduleData, "contentKey")
    if (moduleType === 'selfService' && !enabledCategory) {
      return (
        <div className="col-12  col-xl-12 form-group">
          <label className="">Category</label>
          <select
            value={category}
            className="form-control "
            onChange={(e) => UpdateFormInput(e)}
            name="Category"
          >
            <option value={""}>{"Select"}</option>
            {
              Array.isArray(uniqueData) && uniqueData.length > 0 && uniqueData.map((item, index) => (
                <option key={index} value={item.contentKey}>{item.contentKey}</option>
              ))
            }
          </select>
          <div className={classes.justifyContentRight}>
            <button
              type="button"
              className="btn btn-apply "
              onClick={(e) => {
                e.preventDefault();
                addNewCaregory(true);
              }}
            >
              Add New Category
            </button>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="col-12  col-xl-12 form-group ">
          <label className="">Category</label>
          <input
            value={category}
            type="text"
            className="form-control "
            placeholder="Enter category for the Report"
            onChange={(e) => UpdateFormInput(e)}
            name="Category"
          />
          <div className={classes.justifyContentRight}>
            <button
              type="button"
              className={classes.addCustomUser}
              onClick={(e) => {
                e.preventDefault();
                addNewCaregory(false);
              }}
            >
              Back
            </button>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={classes.root}>
      <div className="container">
        <div className="col-12 sections my-2" id="basic_screen">
          <form className="form">
            <div className="row">
              <div className="col-12  col-xl-12 form-group ">
                <label className="">Display Name</label>
                <input
                  value={displayName}
                  type="text"
                  className="form-control "
                  placeholder="Enter Display Name of the Report"
                  onChange={(e) => UpdateFormInput(e)}
                  name="displayName"
                />
              </div>
              <div className="col-12  col-xl-12 form-group ">
                <label className="">Description</label>
                <input
                  value={description}
                  type="text"
                  className="form-control "
                  placeholder="Enter short description"
                  onChange={(e) => UpdateFormInput(e)}
                  name="description"
                />
              </div>
              <div className="col-12  col-xl-12 form-group ">
                <label className="">
                  Link for Folder{" "}
                  <small>(Ensure the link has http(s) included)</small>
                </label>
                <input
                  value={linkForDocumentation}
                  type="text"
                  className="form-control "
                  placeholder="Enter Report link"
                  onChange={(e) => UpdateFormInput(e)}
                  name="Links"
                />
              </div>
              {
                renderCategory()
              }

              <div>{error}</div>
              <div
                className="col-12  col-xl-12 form-group  flex mt-0"
                style={{ justifyContent: "flex-end" }}
              >
                <button
                  type="button"
                  className="btn btn-apply"
                  onClick={(e) => {
                    e.preventDefault();
                    saveNewReportLinks();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ReportLinks;