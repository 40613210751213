import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import cloneDeep from "lodash/cloneDeep";
import {
  updateReportSectionParams,
  updateMsalTokenFlag,
  updateActiveSubMenuList,
  updateActiveReportSection,
  getEmbedToken,
  updateBookmarkViews,
  updateActiveMenuList
  // updatePlaybooksContentPages
} from '../../redux/actions/index';
import { useStyles } from './subMenuStyle.js';
// import DotFlashing from '../../component/DotFlashing/index';
import { useHistory } from "react-router-dom";
import { UserAgentApplication } from "msal";
import { handleSilentLogin, loginRequest, msalConfig } from "../../component/msal/loginrequest";
import toastProperties from "../../assets/data/toastProperty";
import moment from 'moment';
import utils from '../../utils/index';

const SubMenuList = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    playbookContentPages,
    getMenuList,
    loading,
    playbookData,
    // subRegion,
    // leverIndex,
    error,
    errorMessage,
    activeSubMenuDisplayName,
    activeSubMenuID
  } = props;

  const [activeReportDisplayName, setActiveReportDisplayName] = useState('');
  const [activeReportID, setActiveReportID] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [defaultValue, setDefaultValue] = useState(true);
  const wrapperRef = useRef();

  let history = useHistory();
  const msalInstance = new UserAgentApplication(msalConfig);


  const pbiReport = useSelector((state) => state.PowerBIReportPageSection);
  const playbooksRegionFilter = useSelector((state) => state.playbooksRegionFilter);
  const playbooksSelectedRegionFilter = useSelector((state) => state.playbooksSelectedRegionFilter);
  const toastTriggered = useSelector((state) => state.exportfile);
  const bookmarkView = useSelector((state) => state.bookmarkView);
  const activeReport = useSelector((state) => state.activePBIReport);
  // const activeReportPage = useSelector((state) => state.PowerBIReportPage);
  const presistApplicable = useSelector((state) => state.updatePresist);
  const { activeMsalToken, activeRegionSpecificConfigs, activeRegion, activePage } = useSelector((state) => state.activeDynamicData);

  //Presist Feature Needs to be restructure just fetch on the basic of click//
  useEffect(() => {
    const report = sessionStorage.getItem('presistReport');
    const presistReport = !!report ? JSON.parse(report) : null;
    let defaultReportSection;
    let activeChildIndex;
    console.log('@updateNonPresistReportSectionDisplayName 000', playbookContentPages, activeSubMenuDisplayName, activeSubMenuID, activeReportDisplayName, activeReportID,);
    if (playbookContentPages && Array.isArray(playbookContentPages) && playbookContentPages.length > 0 && !presistReport) {
      if (playbookData.grouped && activeRegion === playbookData['region']) {
        let activeChildIndex = playbookContentPages.filter(el => el.parentReportID === playbookData.viewId).findIndex(page => page.displayName === activeSubMenuDisplayName)
        if (activeChildIndex > -1) {
          setActiveIndex(activeChildIndex)
          setActiveReportID(playbookContentPages[activeChildIndex].reportPageId)
          setActiveReportDisplayName(playbookContentPages[activeChildIndex].displayName);
        }
        else if (activeChildIndex === -1) {
          let additionalReportIndex = playbookContentPages.filter(el => el.parentReportID === playbookData.viewId).findIndex(page => page.displayName.toLowerCase().startsWith(activeSubMenuDisplayName.toLowerCase()))
          console.log('@updateNonPresistReportSectionDisplayName', 111, activeChildIndex, additionalReportIndex, activeSubMenuDisplayName, activeSubMenuID, activeSubMenuDisplayName.toLowerCase())
          if (additionalReportIndex > -1) {
            setActiveIndex(additionalReportIndex)
            setActiveReportID(playbookContentPages[additionalReportIndex].reportPageId)
            setActiveReportDisplayName(playbookContentPages[additionalReportIndex].displayName);
          }
          else {
            setActiveIndex(0)
            setActiveReportID(playbookContentPages[0].reportPageId)
            setActiveReportDisplayName(playbookContentPages[0].displayName);
          }
        }
      }
      else if (!playbookData['grouped']) {
        if (activeReportDisplayName || activeReportID) {
          defaultReportSection = playbookContentPages.find(page => page.displayName.trim() === activeReportDisplayName.trim() || page.name === activeReportDisplayName || page.name === activeReportID);
          activeChildIndex = playbookContentPages.findIndex(page => page.displayName.trim() === activeReportDisplayName.trim() || page.name === activeReportID)
        }
        else if (activeSubMenuDisplayName || activeSubMenuID) {
          defaultReportSection = playbookContentPages.find(page => page.displayName.trim() === activeSubMenuDisplayName.trim() || page.name === activeSubMenuDisplayName || page.name === activeSubMenuID);
          activeChildIndex = playbookContentPages.findIndex(page => page.displayName === activeSubMenuDisplayName || page.name === activeSubMenuID)
        }
        else {
          defaultReportSection = playbookContentPages.find(page => page.order === activeIndex)
        }
        let reportSectionName = defaultReportSection ? defaultReportSection.name : playbookContentPages[0] && playbookContentPages[0].name;
        let reportSectionDisplayName = defaultReportSection ? defaultReportSection.displayName : playbookContentPages[0] && playbookContentPages[0].displayName;
        console.log('@updateNonPresistReportSectionDisplayName 111', playbookData, activeChildIndex, activeRegion, activeIndex, defaultReportSection, reportSectionDisplayName, reportSectionName, playbookContentPages, activeReportDisplayName, activeReportID);
        if (activeRegion === playbookData['region']) {
          if (!!pbiReport && Object.keys(pbiReport).length > 0) {
            if (playbookData.reportPages) {
              if (activeChildIndex > -1 && activeChildIndex !== activeIndex) {
                console.log('@updateNonPresistReportSectionDisplayName 555', activeIndex, reportSectionName, reportSectionDisplayName);
                setActiveReportDisplayName(reportSectionDisplayName);
                setActiveIndex(activeChildIndex);
                setActiveReportID(reportSectionName)
              }
              else {
                console.log('@updateNonPresistReportSectionDisplayName 666', activeIndex, reportSectionName, reportSectionDisplayName);
                setActiveReportDisplayName(reportSectionDisplayName);
                setActiveIndex(activeIndex);
                setActiveReportID(reportSectionName)
              }
            }
            else if (!playbookData.reportPages) {
              console.log('@updateNonPresistReportSectionDisplayName 666', activeIndex, reportSectionName, reportSectionDisplayName);
              setActiveReportDisplayName(reportSectionDisplayName);
              setActiveIndex(activeIndex);
              setActiveReportID(reportSectionName)
            }
          }
        }
      }
    }
  }, [pbiReport, playbookData, activeRegion, playbookContentPages, activeIndex, presistApplicable, dispatch, activeSubMenuDisplayName, activeSubMenuID, activeReportDisplayName, activeReportID]);


  useEffect(() => {
    if (pbiReport && Object.keys(pbiReport).length > 0) {
      pbiReport.setPage(activeReportID ? activeReportID : activeSubMenuID);
    }
  }, [activeReportID, activeSubMenuID, defaultValue, pbiReport])

  // useEffect(()=>{
  //   if (playbookContentPages && Array.isArray(playbookContentPages) && playbookContentPages.length > 0 ){
  //     let params = {}; 
  //     params.reportSectionIndex = 0
  //     params.activeSubMenu = playbookContentPages[0]['reportPageId'];
  //     params.activeSubMenuName = playbookContentPages[0]['displayName'];
  //     params.reportPage = playbookContentPages[0]['reportPage'];
  //      dispatch(updateActiveReportSection(params))
  //   }
  // },[ dispatch, playbookContentPages])

  useEffect(() => {
    const report = sessionStorage.getItem('presistReport');
    const presistReport = !!report ? JSON.parse(report) : null;
    if (playbookContentPages && Array.isArray(playbookContentPages) && playbookContentPages.length > 0 && !!presistReport) {
      if (activeRegion === playbookData['region']) {
        if (presistReport.type === "state" || presistReport.type === "share") {
          if (!!pbiReport && Object.keys(pbiReport).length > 0) {
            if (Object.keys(bookmarkView).length > 0) {
              pbiReport.bookmarksManager.applyState(presistReport.state);
              let reportSectionName = bookmarkView.reportSectionName;
              let reportSectionId = bookmarkView.reportSectionId;
              let activeIndex = playbookContentPages.findIndex(page => page.displayName === reportSectionName);
              console.log('@updatePresistReportSectionDisplayName 111', activeRegion, bookmarkView, reportSectionName, activeIndex, playbookContentPages);
              setActiveReportDisplayName(reportSectionName);
              setActiveIndex(activeIndex);
              setActiveReportID(reportSectionId)
            }
            else if (Object.keys(bookmarkView).length === 0) {
              console.log('@updatePresistReportSectionDisplayName 222', activeRegion, bookmarkView,);
              let defaultReportSection = playbookContentPages[0];
              let reportSectionName = defaultReportSection.name;
              let reportSectionDisplayName = defaultReportSection.displayName;
              setActiveReportDisplayName(reportSectionDisplayName);
              setActiveIndex(0);
              setActiveReportID(reportSectionName);
            }
          }
        }
      }
    }
  }, [playbookData, activeRegion, playbookContentPages, bookmarkView, dispatch, pbiReport, activeMsalToken]);

  useEffect(() => {
    let appliedFilter = [];
    let pageWithSlicer;
    if (activePage['globalFilter'] && playbooksSelectedRegionFilter && Object.keys(playbooksSelectedRegionFilter.dataSet).length > 0 && pbiReport != null) {
      let filters = [];
      console.log("Slicer State--intially", activePage, playbooksRegionFilter, playbooksSelectedRegionFilter)
      !!playbooksSelectedRegionFilter && Object.keys(playbooksSelectedRegionFilter['dataSet']).map((item) => {
        console.log("Slicer State--intially", item, activeRegion)
        filters.push({
          $schema: "http://powerbi.com/product/schema#slicerTargetSelector",
          target: playbooksRegionFilter['schema'][activeRegion]['filterList'][item]['target'],
          filterType: 1,
          operator: "In",
          values: Array.isArray(playbooksSelectedRegionFilter.dataSet[item]['activeItem']) ? playbooksSelectedRegionFilter.dataSet[item]['activeItem'] : [playbooksSelectedRegionFilter.dataSet[item]['activeItem']],
          requireSingleSelection: false
        })
      });
      !!pbiReport && Object.keys(pbiReport).length > 0 &&
        pbiReport.getPages().then((allPage) => {
          if (activeReportDisplayName) {
            pageWithSlicer = allPage.filter(function (page) { return page.displayName === activeReportDisplayName })[0];
            console.log("000_allSlicers-1", 111, allPage, pageWithSlicer, activeReportDisplayName);
          }
          else {
            pageWithSlicer = allPage.filter(function (page) { return page.isActive })[0];
            console.log("000_allSlicers-1", 222, pageWithSlicer, activeReportDisplayName);
          }
          pageWithSlicer && pageWithSlicer.getVisuals().then((vis) => {
            console.log("000_allSlicers-2", pageWithSlicer, vis)
            let slicer = vis.filter(function (visual) { return visual.type === "slicer" });
            console.log("Slicer State--01", slicer)
            slicer.forEach((item, index) => {
              console.log("Slicer State--02", item)
              item.getSlicerState().then((res) => {

                console.log("Slicer State--03", res, filters)
                filters.map((filter, index) => {
                  console.log("Slicer State--04", res, filter)
                  if (res && res['targets'][0]['column'] === filter['target']['column']) {
                    console.log("Slicer State--05", res['targets'][0], filter['target']['column'], filter['values'])
                    let tempFilter = {}
                    if (filter.values.length === 0) {
                      tempFilter.filters = [];
                      tempFilter.targets = res['targets'][0]
                    }
                    else {
                      tempFilter.filters = [filter];
                      tempFilter.targets = res['targets'][0]
                    }
                    item.setSlicerState(tempFilter).then((res) => {
                      console.log("Slicer State--03 --", res)
                    }).catch((err) => {
                      console.log("Slicer State--04", err);
                    });
                    appliedFilter.push(tempFilter);
                    sessionStorage.setItem('appliedFilter', JSON.stringify(appliedFilter));
                    return filter
                  }
                })
                  .catch((err) => {
                    console.log("Slicer State--04", err);
                  })
              })
            })
          })
            .catch((err) => {
              console.log("Slicer State--err", err);
            });
          console.log("111_allSlicers", filters,);
        })
          .catch((err) => {
            console.log('err', err)
            console.log("Slicer State--err", err);
          })
    }
  }, [playbooksSelectedRegionFilter, pbiReport, playbooksRegionFilter, activeRegion, activePage, activeReportDisplayName]);

  let compareAndUpdateMsalAccessToken = () => {
    if (activeRegionSpecificConfigs['msalTokenEnabled']) {
      let sessionData = sessionStorage.getItem("msalAccessTokenExpiry")
      if (sessionData) {
        let nearExpiry = moment(sessionData).diff(new Date(), 'minutes') < 5
        if (nearExpiry) {
          if (process.env.REACT_APP_ENVIRONMENT === "LOCAL") {
            toastProperties.error["description"] = 'MSAL Access Token is expired, Please get a new token';
            dispatch(toastTriggered([toastProperties.error]));
          }
          else if (msalInstance.getAccount()) {
            dispatch(updateMsalTokenFlag(true, "submenu"))
            handleSilentLogin(msalInstance, loginRequest);
          } else {
            history.push({
              pathname: "/msalcallback",
              state: {},
            });
          }
        }
      }
    }
    return true;
  };

  useEffect(() => {
    let { htmlTemp, grouped, msalEnabled, customizableFlag, isReportActive } = activeReport;
    if (grouped && !msalEnabled && isReportActive) {
      let reportId = utils.checkValidPBIds(htmlTemp, customizableFlag);
      console.log('SelectedAactiveReport', activeReport, reportId, htmlTemp)
      if (reportId && reportId.reportId === htmlTemp.reportId && defaultValue) {
        dispatch(getEmbedToken(reportId));
      }
    }
  }, [activeIndex, activeReport, defaultValue, dispatch]);

  const selectActiveSubMenu = (e, reportSection, index) => {
    console.log();
    const { grouped, msalEnabled, isReportActive, htmlTemp } = activeReport;
    dispatch(updateReportSectionParams({}));
    compareAndUpdateMsalAccessToken();
    dispatch(updateBookmarkViews({}));
    setActiveReportDisplayName(reportSection.displayName);
    setActiveIndex(index);
    setDefaultValue(false);
    setActiveReportID(reportSection.reportPageId)
    getMenuList(reportSection.reportPageId, index, reportSection.displayName, reportSection['htmlTemp']);
    const data = {
      activeSubMenu: reportSection.reportPageId,
      activeSubMenuIndex: index,
      reportSectionDisplayName: reportSection.displayName,
      htmlTemp: reportSection.htmlTemp,
      viewName: activeReport.viewName,
      viewId: activeReport.viewId,
      isReportPage: reportSection.reportPage
    };
    if (activeReport.isReportActive && activeReport.grouped) {
      if (htmlTemp && htmlTemp.reportId !== reportSection.htmlTemp.reportId) {
        console.log('updateActiveSubMenu', 555, activeReport, activeIndex, reportSection, data, htmlTemp, htmlTemp.reportId, reportSection.htmlTemp.reportId);
        dispatch(updateActiveSubMenuList(data));
      }
    }
    let params = {
      activeSubMenu: reportSection.reportPageId,
      reportSectionIndex: index,
      activeSubMenuName: reportSection.displayName,
      reportPage: reportSection.reportPage
    }
    dispatch(updateActiveReportSection(params));
    sessionStorage.setItem('presistBookmarkIndex', null);
    if (isReportActive && grouped && !msalEnabled) {
      let customizableFlag = reportSection.customizableFlag;
      let reportIds = utils.checkValidPBIds(reportSection.htmlTemp, customizableFlag);
      if (reportIds && reportIds.reportId !== htmlTemp.reportId) {
        dispatch(getEmbedToken(reportIds));
      }
    }
    if (pbiReport && Object.keys(pbiReport).length > 0) {
      if (reportSection.reportPage) {
        pbiReport.setPage(reportSection.reportPageId);
      }
      else {
        console.log('selectActiveReportSection', 222, reportSection, pbiReport, reportSection.name);
        pbiReport.setPage(reportSection.name);
      }
    }
  };

  const renderSubMenuList = () => {
    console.log('renderSubMenuList', 666, playbookContentPages, loading, error, playbookContentPages);
    if (!!playbookContentPages && Array.isArray(playbookContentPages)) {
      if (!loading && !!error && playbookContentPages.length === 0) {
        console.log('renderSubMenuList', 666, playbookContentPages);
        return (
          <ListItem button key={0} className={classes.menuList}>
            <ListItemText className={classes.disableText} secondary={errorMessage} />
          </ListItem>
        )
      }
      else if (!loading && playbookContentPages.length > 0) {
        console.log('renderSubMenuList', 777, playbookContentPages, activeIndex);
        return (
          playbookContentPages.map((text, index) => (
            <ListItem button
              disableRipple
              key={text + index}
              className={index === activeIndex ? classes.activeSubMenuList : classes.menuList}
              onClick={(e) => selectActiveSubMenu(e, text, index)}
            >
              <ListItemText secondary={text.displayName} />
            </ListItem>
          )
          )
        )
      }
    }
  }

  return (
    <div id="#subMenuList" ref={wrapperRef}>
      <List>
        {renderSubMenuList()}
      </List>
    </div>
  );
}

export default SubMenuList;