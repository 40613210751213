import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    updateResourcesDisplayOrder,
    toastTriggered,
    getModuleContent
} from '../../redux/actions';
import utils from '../../utils/index';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BarLoader from "react-spinners/BarLoader";
import cloneDeep from 'lodash/cloneDeep';
import toastProperties from "../../assets/data/toastProperty";

const ModuleOrder = (props) => {

    const  { closeDialog, moduleData} =  props;

    const dispatch = useDispatch();
    const { activeRegion, activePage } = useSelector((state) => state.activeDynamicData);
    const moduleRequestUpdate = useSelector((state) => state.moduleRequest);
     
    const [itemList, setItemList] = useState([]);
    const [customMessage, setCustomMessage] = useState({});

    useEffect(() => {
        if(Array.isArray(moduleData) && moduleData.length>0 ){
            setItemList(moduleData)
        }
    }, [moduleData]);
 
    const trackEvents = (moduleData,type) => {
        utils.userTrackingInfo({
           region: activeRegion,
           actionType: 'Landing Page Admin Activity',
           pageName: 'Admin Dashboard',
           reportName: moduleData.title,
           pageDetails: type,
       });
   }

    const saveModuleOrder = () => {
        let updatedResourcesPosition = [];
        let newOrder = cloneDeep(itemList);
        let customObj = {};
        customObj.loading = true;
        setCustomMessage(customObj);
        newOrder.map((module, index) => {
            module.order = index + 1;
            let resourcesOrder = {};
            resourcesOrder.order = module.order;
            resourcesOrder.LinkId = module.LinkId;
            resourcesOrder.displayName = module.displayName;
            updatedResourcesPosition.push(resourcesOrder)
            return module;
        });
        let params = { order: updatedResourcesPosition, region :  activeRegion };
        console.log('updatedResourcesPosition', params);
        dispatch(updateResourcesDisplayOrder(params))
            .then((res) => {
                toastProperties.success["description"] = res.data.message;
                dispatch(toastTriggered([toastProperties.success]));
                closeDialog();
                const pageContent = {
                    pageType:  activePage.key,
                    region: activeRegion,
                  }
                dispatch(getModuleContent(pageContent));
                customObj.loading = false;
                setCustomMessage(customObj);
                trackEvents(updatedResourcesPosition[0],  'Resources Order Updated SuccessFully')
            })
            .catch(() => {
                toastProperties.error["description"] = `There is some problem while updating parent module display order. Please try again in some time.`;
                dispatch(toastTriggered([toastProperties.error]));
                closeDialog();
                customObj.loading = false;
                setCustomMessage(customObj);
            });
    }

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList);
    };

    const renderEventMessage = () => {
        if (customMessage.loading) {
            return (
                <div className="waiting_Loader">
                    <div className="prettier">Please wait...</div>
                    <BarLoader
                        size={150}
                        color={"#123abc"}
                        height={4}
                        width={100}
                        loading={true}
                    />
                </div>
            )
        }
        else if (moduleRequestUpdate.error) {
            return (
                <div className="col-12  col-xl-12 flex mt-0" style={{ justifyContent: "flex-start" }}>
                    <div className="col-12  col-xl-12 error"> Error : {moduleRequestUpdate.message}</div>
                </div>
            )
        }
    }

    return (
        <div className="container">
            <div className="col-12 sections my-2" id="basic_screen">
                <form className="form">
                    <div className="row">
                        <div className="reportOrder">
                            <DragDropContext onDragEnd={handleDrop}>
                                <Droppable droppableId="list-container">
                                    {(provided) => (
                                        <div
                                            className="list-container"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            { itemList.map((item, index) => (
                                                <Draggable key={item.LinkId} draggableId={item.displayName} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            className="item-container"
                                                            ref={provided.innerRef}
                                                            {...provided.dragHandleProps}
                                                            {...provided.draggableProps}
                                                        >
                                                            {item.displayName}
                                                            <span className="reOrder"><i class="fa fa-bars" aria-hidden="true"></i></span>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <div className="arrangments">
                                {renderEventMessage()}
                                {
                                    customMessage.loading ?
                                        null
                                        :
                                        <button className="btn btn-danger tile-new" onClick={(e) => { e.preventDefault(); saveModuleOrder() }}>  Save </button>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ModuleOrder;

