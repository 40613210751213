import apiInstance from './index';

export const Api = {
  login: (params) => apiInstance.post('/signIn', params),
  signUp: (params) => apiInstance.post('/signUp', params),
  signOut: () => apiInstance.get(`${process.env.REACT_APP_ISSUER}/v1/logout`),
  testingAPI: (params) => apiInstance.post('/api/newApi', params),
  getRegion: () => apiInstance.get('/api/getSubRegion'),
  globalSearchQuery: (params) =>  apiInstance.post('/api/globalSearchQuery', params),
  sideBarMenuList : (params) => apiInstance.post('/api/getSideBarContent', params),
  getPageContent: (params) => apiInstance.post('api/getPageContent', params),
  getModuleContent: (params) => apiInstance.post('api/getModuleContent', params),
  getPlaybookContent: (params) => apiInstance.post('api/getPlaybookContent', params),
  getPlaybookContentPages: (params) => apiInstance.post('api/getPagesByReport', params),
  getMsalBasedPlaybookContentPages: (params) => apiInstance.post('/api/subReports', params),
  playbookSubReports: (params) => apiInstance.post('/api/playbookSubReports', params),
  getEmbedToken: (params) => apiInstance.post('api/getEmbedToken', params),
  getReportContent: (params) => apiInstance.post('api/getReportContent', params),
  dynamicReportContent : (params) => apiInstance.post('api/dynamicReportContent', params),
  getDashboardStatus: (params) => apiInstance.get(`api/getDashboardStatus?region=${params.region}`),
  getPlayBooksRegionFilters: (params) => apiInstance.get(`api/globalFiltersSQL?region=${params.region}&userId=${params.userId}&subRegion=${params.subRegion}`),
  getShareLinkData: (params) => apiInstance.get(`api/getShareLinkData?shareLinkID=${params.shareLinkID}&hashedId=${params.hashedId}`),
  getErrorMessage: () => apiInstance.get(`api/getErrorMessage`),
  updateErrorMessages: (params) => apiInstance.put(`api/editErrorMessage`, params),
  getFaqlink: (params) => apiInstance.post('/api/getFaqContent', params),
  alertField: () => apiInstance.get('/api/fields'),
  operationalAlertsType: () => apiInstance.get('api/alertTypes'),
  operationAlert: () =>apiInstance.get('api/alertList'),
  createOperationalAlerts: (params) => apiInstance.post('api/configuration/alert', params),
  updateOperationalAlerts: (params) => apiInstance.post('api/editAlert', params),
  targetUser : (params) => apiInstance.get(`api/userMapping?data=${JSON.stringify(params)}`),
  userCountry : (params) => apiInstance.get(`api/countries?region=${JSON.stringify(params)}`),
  userMarket : (params) => apiInstance.get(`api/markets?region=${JSON.stringify(params)}`),
  userTeam : (params) => apiInstance.get(`api/teams?country=${JSON.stringify(params)}`),
  publishAlerts : (params) => apiInstance.post('api/publishAlerts', params),
  deleteAlert: (params) => apiInstance.delete(`api/deleteAlert?alertID=${params.alertID}`),
  userSubscribeData: (params) => apiInstance.get(`api/subscriptions?region=${params.region}&userEmail=${params.email}`),
  userSubscription:  (params) => apiInstance.post('api/editSubscriptions', params),
  notification: (params) => apiInstance.get(`/api/notifications?userEmail=${params.email}`),
  clearNotifications:  (params) => apiInstance.post('api/clearNotifications', params),
  releaseFields: () => apiInstance.get('api/releaseFields'),
  createRelease: (params) => apiInstance.post('api/createRelease', params),
  releaseVersionList: (params) => apiInstance.get(`api/versionList?region=${params.region}`),
  releaseDataList: (params) => apiInstance.get(`api/releaseDataList?releaseVersionID=${params.releaseVersionID}&releaseName=${params.releaseName}&region=${params.region}`),
  getReleaseTitleData: (params) => apiInstance.get(`api/releaseDetailedNotes?releaseVersionID=${params.releaseVersionID}&region=${params.region}`), //releaseCategoryID=${params.releaseCategoryID}&subCategory=${params.subCategory}
  createNewReleaseTitle: (params) => apiInstance.post('api/createSubCategory', params),
  publishRelease: (params) => apiInstance.post('api/updateRelease', params),
  regionalMappingData : (params) => apiInstance.get(`api/componentList?component=${params.component}&region=${params.region}`),
  createNewsletter: (params) => apiInstance.post(`api/createNewsLetter`,params),
  newsletterMonth: () => apiInstance.get(`api/monthDropdown`),
  getNewsletterData: (params)=>apiInstance.get(`api/getNewsLetter?region=${params.region}&month=${params.month}`),
  updateNewsletter: (params) => apiInstance.post(`api/updateNewsLetter`,params),
  updateModuleName: (params) => apiInstance.post(`api/editModuleName`,params),
  deleteModuleName: (params) => apiInstance.delete(`api/deleteModule?ID=${params.deleteID}&region=${params.region}&module=${params.module}`),
  deleteSubCategory: (params) => apiInstance.post('api/deleteSubCategory', params),
  editSubCategoryName : (params) => apiInstance.post('api/editSubCategoryName', params),
  getSelectedSubReportDetails:   (params) => apiInstance.post('api/selectedSubReportDetails', params),
  deleteReport : (params) => apiInstance.delete(`api/deleteReport?viewId=${params.viewId}`),
  deleteSubReport : (params) => apiInstance.delete(`api/deleteSubReport?subReportId=${params.subReportId}`),
  regionBuId:(params) => apiInstance.post('/api/regionBuId',params),
  parentBuMappedId :  (params) => apiInstance.post('/api/parentBuMappedId',params),
  childParentMappedId: (params) => apiInstance.post('/api/childParentMappedId',params),
  addNewParentModule: (params) => apiInstance.post('/api/addNewParentModule',params),
  updateParentModule: (params) => apiInstance.post('/api/updateParentModule',params),
  addNewChildModule: (params) => apiInstance.post('/api/addNewChildModule',params),
  updateChildModule: (params) => apiInstance.post('/api/updateChildModule',params),
  parentModuleOrder: (params) => apiInstance.post('/api/updateParentModuleOrder',params),
  childModuleOrder : (params) => apiInstance.post('/api/updateChildModuleOrder',params),
  inActivateParentModule:  (params) => apiInstance.post(`api/deActivateParentModule`, params),
  deleteParentModule : (params) => apiInstance.post(`api/deleteParentModule`, params),
  deleteChildModule : (params) => apiInstance.post(`api/deleteChildModule`, params),
  userProfile:  (params) => apiInstance.get(`api/userProfile?userEmail=${params.email}`),
  addNewUser : (params) => apiInstance.post(`api/addNewusers`, params),
  updateUserLogin: (params) => apiInstance.post(`api/updateUserLogin`, params),
  updateUserProfile : (params) => apiInstance.post(`api/updateUser`, params),

};
