import React, { useState, useEffect /*, lazy, Suspense */ } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import GlobalStyles from "../views/GlobalStyles";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import ErrorBoundary from "./ErrorBoundary";
import Header from "../component/Header";
import Toast from "../component/Toast/index";
import Layout from "../views/layoutContent/index";
import Home from "../views/Home/index";
import Playbooks from "../views/playBookReports/index";
import SelfService from "../views/selfService/index";
import FoundationAnalytics from "../views/foundationAnalytics/index";
import AdditionalReports from "../views/additionalReports/index";
import Resources from "../views/resources/index";
import ControlTower from "../views/controlTower/index";
import viewBuilder from "../views/viewBuilder/Admin";
import DashboardBuilder from "../views/viewBuilder/DashboardBuilder";
import Share from "../views/share/index";
import DynamicReports from "../views/dynamicReports";
import DynamicReportLinks from "../views/dynamicReportLinks";
import NewTabOpen from "../views/newtab/index";
import Alert from "../component/Alert/index";
import MSALCallback from "../component/msal/MSALCallback";
import CreateAlert from "../views/Notification/createAlert";
import Notification from "../views/Notification/index";
import AlertSetting from "../views/Notification/alertSetting";
import ReleaseNotes from "../views/releaseNote";
import Newsletter from "../views/newsletter";
import PageNotFound from "../component/pageNotFound";
import EditBusinessUnit from "../views/EditBusinessUnit";
import LandingPreview from "../views/EditBusinessUnit/preview";
import UserProfile from "../views/profile";
import updateUsers from "../views/profile/createUsers";
import CCUsers from "../views/profile/users";

// const Layout = lazy(() => import('../views/layoutContent/index'));
// const Home = lazy(() => import('../views/Home/index'));
// const Playbooks = lazy(() => import('../views/playBookReports/index'));
// const SelfService = lazy(() => import('../views/selfService/index'));
// const AdvanceAnalytics = lazy(() => import('../views/advancedAnalytics/index'));
// const AdditionalReports = lazy(() => import('../views/additionalReports/index'));
// const Resources = lazy(() => import('../views/resources/index'));
// const usageDashBoard = lazy(() => import('../views/usageDashboard/index'));
// const viewBuilder = lazy(() => import('../views/viewBuilder/Admin'));
// const DashboardBuilder = lazy(() => import('../views/viewBuilder/DashboardBuilder'));

// React — Loading Chunk Failed Error Solution
// function componentLoader(lazyComponent, attemptsLeft) {
//     return new Promise((resolve, reject) => {
//       lazyComponent()
//         .then(resolve)
//         .catch((error) => {
//           // let us retry after 1500 ms
//           setTimeout(() => {
//             if (attemptsLeft === 1) {
//               reject(error);
//               return;
//             }
//             componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
//           }, 1500);
//         });
//     });
// }

const config = {
  clientId: process.env.REACT_APP_CLIENTID,
  issuer: process.env.REACT_APP_ISSUER,
  responseType: "id_token",
  redirectUri: `${window.location.origin}/callback`,
  scopes: ["openid", "profile", "email"], //".kc.samaccountname", 
  pkce: true,
};

const oktaAuth = new OktaAuth(config);

const CustomRoute = () => {

  const toastData = useSelector((state) => state.exportfile);
  const apiFailure = useSelector((state) => state.apiFailure);
  const errorMessage = useSelector((state) => state.getErrorMessages);
  const { activeRoutes, activeRegion } = useSelector((state) => state.activeDynamicData);

  const [autoDeleteTime, setAutoDeleteTime] = useState(1000000000);
  const [autoDelete, setAutoDelete] = useState(false);
  const [exportTriggerd, setExportTriggered] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("warning");
  const [message, setMessage] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [apiName, setApiName] = useState("");
  const history = useHistory();
  const regionlist = useSelector((state) => state.regions);

  useEffect(() => {
    if (!!apiFailure.error && apiFailure.type === "NetworkError") {
      history.replace("/");
      setAlertType("Error");
    } else if (!!apiFailure.alertTrigger && !!apiFailure.error && apiFailure.type === "404NotFound") {
      setShowAlert(true);
      setMessage(apiFailure.message);
      setAlertType("Error");
    } else if (!!apiFailure.alertTrigger && !!apiFailure.error && apiFailure.type === "ApiError") {
      setShowAlert(true);
      setMessage(apiFailure.message);
      setUserMessage(apiFailure.userMessage);
      setAlertType("Error");
    }
  }, [apiFailure.alertTrigger, apiFailure.apiName, apiFailure.error, apiFailure.message, apiFailure.type, apiFailure.userMessage, history]);

  useEffect(() => {
    if (apiFailure.type === "ApiSlow") {
      const serverNodeDown = errorMessage?.data?.find((item) => item.errorType === "Unresponsive Server");
      const slowAPI = errorMessage?.data?.find((item) => item.errorType === "Slow API");
      let info = !!serverNodeDown ? serverNodeDown.errorMessage : apiFailure.message;
      let slowAPICase = !!slowAPI ? slowAPI.errorMessage : apiFailure.userMessage;
      if (!!apiFailure.alertTrigger && !!apiFailure.error) {
        setShowAlert(true);
        setUserMessage(slowAPICase);
        setMessage(info);
        setApiName(apiFailure.apiName);
        setAlertType("Info");
      } else if (!apiFailure.alertTrigger && !apiFailure.error) {
        setShowAlert(false);
      }
    }
  }, [apiFailure.alertTrigger, apiFailure.apiName, apiFailure.error, apiFailure.message, apiFailure.type, apiFailure.userMessage, errorMessage.data]);

  useEffect(() => {
    let time = Array.isArray(toastData) && toastData.length > 0 ? toastData[0].autoDeleteTime : 1000000000;
    let autoDelete = Array.isArray(toastData) && toastData.length > 0 && toastData[0].autoDelete;
    let trigger = Array.isArray(toastData) && toastData.length > 0 && toastData[0].trigger;
    setExportTriggered(trigger);
    setAutoDeleteTime(time);
    setAutoDelete(autoDelete);
  }, [toastData]);

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    console.log('restoreOriginalUri', history, originalUri, window.location.origin );
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const handleModalClose = () => {
    setShowAlert(false);
  };

  return (
    <>
      {/* <ErrorBoundary> */}

      {/* <Suspense fallback={ <CircularProgress style={{position: 'absolute', top: '50%'}}/>}> */}

      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>

        <Toast
          toastList={toastData}
          position="top-right"
          dismissTime={autoDeleteTime}
          autoDelete={autoDelete}
          exportTriggerd={exportTriggerd}
        />

        <Alert
          open={showAlert}
          message={message}
          handleClose={handleModalClose}
          alertType={alertType}
          errorType={apiFailure.type}
          userMessage={userMessage}
          apiName={apiName}
        />

        <SecureRoute
          render={(routeProps) => {
            return <Header props={routeProps} />
          }}
        ></SecureRoute>

        {!!regionlist && regionlist.hasOwnProperty('data') && Object.keys(regionlist["data"]).length > 0 ? (

          <Switch>
            <SecureRoute exact path="/" component={Home} />

            <SecureRoute
              exact
              path={activeRoutes.key1}
              render={(routeProps) => {
                return <Layout component={Playbooks} props={routeProps} />
              }}
            ></SecureRoute>

            <SecureRoute
              exact
              path={activeRoutes.key2}
              render={(routeProps) => {
                return <Layout component={SelfService} props={routeProps} />
              }}
            ></SecureRoute>

            <SecureRoute
              exact
              path={activeRoutes.key3}
              render={(routeProps) => {
                return (
                  <Layout component={activeRegion === "KCNA" ? SelfService : FoundationAnalytics} props={routeProps} />
                );
              }}
            ></SecureRoute>

            <SecureRoute
              exact
              path={activeRoutes.key4}
              render={(routeProps) => {
                return <Layout component={ControlTower} props={routeProps} />
              }}
            ></SecureRoute>

            <SecureRoute
              exact
              path={activeRoutes.key5}
              render={(routeProps) => {
                return (
                  <Layout component={AdditionalReports} props={routeProps} />
                );
              }}
            ></SecureRoute>

            <SecureRoute
              exact
              path={activeRoutes.key6}
              render={(routeProps) => {
                return <Layout component={Resources} props={routeProps} />;
              }}
            ></SecureRoute>

            <SecureRoute
              exact
              path={activeRoutes.key7}
              component={viewBuilder}
            />

            <SecureRoute
              exact
              path={activeRoutes.key8}
              component={DashboardBuilder}
            />

            <SecureRoute
              exact
              path={activeRoutes.key10}
              component={Notification}
            />

            <SecureRoute
              exact
              path={activeRoutes.key11}
              component={AlertSetting}
            />

            <SecureRoute
              exact
              path={activeRoutes.key12}
              component={CreateAlert}
            />

            <SecureRoute
              exact
              path={activeRoutes.key13}
              component={ReleaseNotes}
            />

            <SecureRoute
              exact
              path={activeRoutes.key14}
              component={Newsletter}
            />
            
            <SecureRoute
              exact
              path={'/user-profile'}
              component={UserProfile}
            ></SecureRoute>

            <SecureRoute
              exact
              path={'/update-user-details'}
              component={updateUsers}
            ></SecureRoute>

            <SecureRoute
              exact
              path={'/users-list'}
              component={CCUsers}
            ></SecureRoute>

            <SecureRoute
              exact
              path={activeRoutes.key15}
              render={(routeProps) =>  <Layout component={DynamicReports } props={routeProps} /> }
            ></SecureRoute>

            <SecureRoute
              exact
              path={activeRoutes.key16}
              render={(routeProps) =>  <Layout component={DynamicReportLinks} props={routeProps} />}
            ></SecureRoute>

            <SecureRoute
              exact
              path={'/landingPage/edit-home-screen'}
              component={EditBusinessUnit} >
            </SecureRoute>

            <SecureRoute
              exact
              path={'/landingPage/preview'}
              component={LandingPreview} >
            </SecureRoute>

            <Route exact path={"/dartboards-new-tab"} component={NewTabOpen} />
            <Route path="/msalcallback" component={MSALCallback} />
            <Route path="/callback" component={LoginCallback} />
            <Route exact path={activeRoutes.key9} component={Share} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        ) :
          (
            <Switch>
              <SecureRoute exact path="/" component={Home} />
              <Route exact path={activeRoutes.key9} component={Share} />
              <Route path="/msalcallback" component={MSALCallback} />
              <Route path="/callback" component={LoginCallback} />
            </Switch>
          )}
      </Security>

      {/* </Suspense> */}
      {/* </ErrorBoundary> */}
    </>
  );
};

export default CustomRoute;
